.main,
body,
html {
  text-align: center;
  background-color: #ebebeb;
}

.main {
  display: flex;
  margin: auto !important;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.section-col {
  background-color: #ffffff;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  margin: 10px 0;
  border-radius: 15px;
}
.section-row {
  background-color: #ffffff;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  margin: 0px 0 10px 0;
  border-radius: 15px;
}

.custom-navbar {
  background-color: #fff !important;
  color: black !important;
}

.social-media {
  margin-top: 25px;
  display: flex;
  width: 90%;
  justify-content: center;
  gap: 15px;
}

.about-me {
  margin: 20px 0 0 0;
  text-align: left !important;
  width: 90%;
}

.hover-highlight:hover {
  color: #0d6efd;
}

.projects {
  text-align: left !important;
  width: 90%;
  margin: 20px 0;
}

.list-item {
  margin-left: 20px;
}

.faq {
  width: 90%;
  text-align: left;
  margin: 30px auto;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat();
}

.slick-prev,
.slick-next {
  z-index: 1;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid rgba(128, 128, 128, 0.5);
}

.slick-prev::before,
.slick-next::before {
  color: rgba(0, 0, 0, 0.404);
  height: 50px;
  height: 50px;
  width: 50px;
}

.more-projects-card {
  box-shadow: 0px 10px 5px -7px rgba(0 0 0 / 20%), 10px 0px 10px -7px rgba(0 0 0 / 20%),
    -10px 0px 10px -7px rgba(0 0 0 / 20%) !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.btns-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
}

.project-card-header span {
  color: black;
  font-weight: 600;
}

@media (max-width: 768px) {
  .section-col,
  .section-row {
    min-width: 100%;
  }
  .slick-prev {
    left: -20px !important;
  }
  .slick-next {
    right: -20px !important;
  }
}

@media (min-width: 769px) {
  .custom-navbar {
    padding: 0 8vw;
  }
  .section-col {
    width: 40vw;
  }
  .section-row {
    width: 81vw;
  }
  .slick-prev {
    left: -50px !important;
  }
  .slick-next {
    right: -50px !important;
  }
}
